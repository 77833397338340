.allusers{
    position: absolute;
    top: 10%;
    left: 20%;
    width: 80%;
    height: 90vh;
    z-index: -1;
}
.new_container{
    padding: 2%;
    height: 50vh;
    display: grid;
    grid-template-columns: auto auto auto;
}

@media screen and (max-width: 900px){

.allusers {
    width: 90%;
    top: 5%;
    left: 5%;
}
}
