.dashboard-tab{
    position: absolute;
    top: 10%;
    left: 20%;
    right: 0;
    bottom: 0;
    width: 80%;
    overflow-y: scroll;
    z-index: -1;
}

.dashboard-tab::-webkit-scrollbar{
    background: transparent;
    width: 16px;
}

.wel_user{
    width: 50%;
    height: 100%;
    background: #4D9DF8;
    border-radius: 8px;
    color: #ffff;
    font-family: "Manrope";
    display: flex;
}
.dashboard_tab_top{
    /* display: flex; */
    margin-top: 4%;
    height: 190px;
}
.wel_u_desc{
    width: 70%;
    /* float: left; */
    padding-left: 10px;
}


/* .wel_user>img{
    float: right;
    width: 30%;
    position: relative;
    top: -100%;
    left: 30px;
} */

.task_num{
    display: flex;
    margin-left: 4%;
    
}
.tasks_ass{
    background-color: #FFDD75;
    height: 90%;
    width: 50%;
    margin-right: 5%;
    border-radius: 16px;
}
.tasks_d{
    height: 90%;
    width: 50%;
    padding: 10px;
    background: #C3EDD5;
    border-radius: 16px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;

}
.task_btn{
    font-size: 18px !important;
}

.show_num{
    font-family: "Manrope";
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: 14px;
}
.show_num>strong{
    font-size: 36px;
    font-style: normal;
    color: rgba(0, 0, 0, 0.65);
}
.show_num>span{
    color: rgba(0, 0, 0, 0.35);
    font-size: 15px;
    width: 50%;
}

.tasks_ass{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    padding: 10px;
}

.quickActions>h1{
    font-family: "Manrope";
    font-size: 20px;
}
.quick_actions{
    display: grid;
    grid-template-columns: auto auto auto ;
}
.actions{
    width: 360px;
    height: 80px;
    background: rgba(3, 143, 219, 0.2);
border-radius: 8px;
display: flex;
align-items: center;
}


#profile{background: rgba(3, 143, 219, 0.2); 
    color: #038FDB;}
#actions{background: rgba(89, 176, 139, 0.2);
    color: #59B08B;}
#tasks{background: rgba(217, 138, 25, 0.2);
    color: #D98A19;
}

.actions_desc{
    display: flex;
    flex-direction: column;
}

.subject-container{
    display: flex;
    position: relative;
    left: -20px;
    /* width: 95%; */
    margin: auto;
    margin-top: 2%;
}

.sub{
    width: 200px;
    height: 50px;
    margin-left: 2%;
    border-radius: 8px;
    padding-top: 10px;
    text-align: center;
    border: 0.5px solid rgb(0,0,0,0.2);
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.sub:hover{
    background: rgba(213, 141, 73, 0.2);
    color: #D58D49;
    border: none;

}


.task_tab{
    display: flex;
    justify-content: space-between;
    height: 80vh;
    margin-top: 4%;
    margin-bottom: 3%;
}

.tasks_details_tab>h1,.tasks_assigned_tab>h1{
    float: left;
    font-size: 20px;
}

.tasks_assigned_tab>div{
    /* display: flex; */
    /* flex-direction: column; */
    margin-top: 2%;
    margin-left: 10px;
    overflow-y: scroll;
    height: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}





.tasks_assigned_tab>div::-webkit-scrollbar{
    background: transparent;
    width: 12px;
    border-radius: 6px;
} 

.tasks_assigned_tab>div::-webkit-scrollbar-thumb{
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: rgb(0,0,0,0.4);
}

.tasks_assigned_tab{
    font-family: "Manrope";
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 87vh;
}

.tasks_details_tab{
    font-family: "Manrope";
    width: 70%;
}

.task_assigned{
    width: 100%;
    height: 150px;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
}

.taskdetails{
    width: 90%;
    height: 88%;
    margin: 2%;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 16px;
    margin-top: 8%;
    padding: 3%;
        }

        .taskdetails>h1{
            font-size: 20px;
        }
        .taskdetails>p{
            color: rgba(0, 0, 0, 0.5);
            font: 12px;
        }

            .task{
                display: flex;
                margin-bottom: 20px;
            }
            .task_desc{
                margin: 35px 10px;
               
            }

            .task_desc_panel{
                display: flex;
                flex-direction: column;
                margin: 20px 20px auto 20px ;

            }

            .task_desc>strong{
                margin-bottom: 10px;
            }
            .task_desc>div{
                font-size: 16px;
            color: rgba(0, 0, 0, 0.5);

            }

            .task_form{
                height: 85%;
            }


            .task_form>button{
                
                position: relative;
                top: 5%;
                left: 70%;
                color: #474EFA;
                background: rgba(71, 78, 250, 0.1);
                border: none;
                padding: 2%;
                border-radius: 4px;

            }

            .task_container{
                width: 98%;
                height: 80%;
                overflow-y: scroll;
                padding: 20px

            }

            #a_help{
                left: 68%;
                background: #ffff;
            }



        .mentor_impact{
            font-family: "Manrope";
        }
        .mentor_impact>h1{
            font-size: 20px;

        }
        .mentor_impact>span{
            position: relative;
            top: -15px;
            font-size: 14px;
        }

        .impact{
            display: flex;
        }

        .impact_stats,.impact_graph{
            width: 50%;
        }
        .impact_stats{
            display: grid;
    grid-template-columns: auto auto
        }
        .stats{
            width: 365px;
            height: 77px;
            background: rgba(0, 0, 0, 0.04);
            opacity: 0.8;
            border-radius: 8px;
            margin: 5px;
            margin-left: 5px;
        }
            .impact_graph{
                display: flex;
                justify-content: center;
            }
        .graph{
            width: 350px;
            height: 140px;
            background: rgba(0, 0, 0, 0.04);
            opacity: 0.8;
            border-radius: 8px;
            margin: 5px;
            margin-left: 5px;
        }

        .mentee_allotment>h1,.connect>h1{
            font-size: 20px;
            font-family: "Manrope";
        }
        .allotment_container{
            display: flex   ;
            font-family:"Manrope";
        }
        .allmentee_container{
            display: flex;
        }
        .allotment{
            width: 300px;
            height: 125px;
            background: rgba(0, 0, 0, 0.04);
            opacity: 0.8;
            border-radius: 8px;
            margin: 5px;
            margin-left: 5px;
            display: flex;
            flex-direction: column;

        }

        .connect{
            margin-bottom: 20px;
        }

        .connect_container{
            width: 55%;
            height: 180px;
            background: rgba(0, 0, 0, 0.04);
            opacity: 0.8;
            border-radius: 8px;
            margin: 5px;
            margin-left: 5px;
        }

        .revenue{
         
            width: 475px;
            height: 170px;
            background: rgba(0, 0, 0, 0.04);
            opacity: 0.8;
            border-radius: 8px;
            margin: 5px;
            margin-left: 35px;
            display: flex;
            align-items: center;
        }


        .revenue_num{
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            align-items: flex-start;
            padding-left: 15px;
        
        }
        .revenue_num>div>strong{
            display: block;
            font-size: 24px;
            font-family: "Manrope";

        }

        .stats{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: "Manrope";
        }
        .stats>strong{
            font-size: 20px;
            margin: 5%;

        }
        .m_t_mon{
            display: flex;
            flex-direction: column;
            margin: 5%;
        }
        .m_t_mon>span{
            font-size: 12px;

        }

        .allotment{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .assigned-task-tab{
            width: 95%;
            margin: auto;
            margin-bottom: 20px;
            height: 60vh;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.12);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            overflow-y: scroll;
        }

        .assigned-task-tab>table{
            width: 100%;
            border-collapse: collapse;

        }
        
.task-table>tr>td, .task-table>tr>th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 2%;
    padding-right: 50px;
    padding-left: 50px;
    font-family: "Manrope";
  }
  
  .table-head{
    background: rgba(117, 98, 246, 0.06);
    font-family: "Manrope";
  }

  .actions{
    cursor: pointer;
  }

  .actions_img>img{
    width: 30px;
    float: right;

  }
  .actions_img>.svg{
    float: left;
  }
  /* .actions_img>img:hover{
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  .actions_img>.svg:hover{
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  } */

  .task-container{
    font-family: "Manrope";
    display: flex;
    flex-direction: column;

  }
  .task-container>label{
    font: 16px;
    font-weight: 600;
    font-family: "Manrope";
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .task-container>input{
    border: none;
    border-radius: 8px;
    background-color: rgb(0,0,0,0.1);
    padding: 3%;
    font-size: 16px;
  }

  #edittask-btn{
    float: right;
    margin: 10px;
    padding: 3%;
    font-size: 16px;
    border-radius: 4px;
    background: black;
    color: #ffff;
}

.plan-expire-alert{
    height: max-content;
    min-height: 60px;
    font-family: "Manrope";
    width: 80%;
    margin: 10px auto;
    border: 1px solid red;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    color: red;
    font-weight: bold;
}

