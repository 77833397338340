.MenteeList {
    position: absolute;
    width: 78%;
    top: 10%;
    left: 20%;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    padding: 10px;
    z-index: -1;

}

/* .MenteeList::-webkit-scrollbar{
    background: transparent;
    width: 16px;
} */
 th, td {
    border: 1px solid #dddddd;
    border-collapse: collapse;
  }

.mentees {
    width: 300px;
    height: 125px;
    background: #FDFDFD;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    margin: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.mentees_btn {

    display: flex;
    justify-content: center;
    align-items: flex-end;

}

.mentees_btn>button {
    background: #263238;
    border-radius: 4px;
    color: #ffff;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    border: none;
    padding: 12px;
    margin: 1%;
}

.mentees_desc {
    display: flex;
    align-items: center;
    font-family: "Manrope";
    justify-content: space-evenly;
    width: 60%;
    margin: 0 auto 0 30px;

}

.mentees_desc>img {
    margin-right: 10px;
}

.mentees_desc>div {
    display: flex;
    flex-direction: column;
    width: 100px;

}

.mentees_desc>div>span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.mentee_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 45vh;
}


.search_mentee {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
}

.search_mentee>input {
    width: 83%;
    padding: 1.5%;
    padding-left: 45px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}

.search_mentee>button {
    background: #1890FF;
    border: none;
    border-radius: 4px;
    width: 80px;
    margin-left: 20px;
    color: white;
    height: 40px;
}

@media screen and (max-width: 900px) {
    .MenteeList {
        width: 90%;
        top: 5%;
        left: 5%;

    }
}